<template>
  <button data-tf-popup="b8SDhDXr" data-tf-iframe-props="title=Contacto The Hub" data-tf-medium="snippet" class="button white">Contáctanos</button>
</template>

<script>
export default {
  name: 'Typeform'
}
const script = document.createElement('script')
script.src = '//embed.typeform.com/next/embed.js'
document.body.appendChild(script)
</script>
