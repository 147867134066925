<template>
  <div class="container">
    <section class="content main">
      <img src="@/assets/img/the-hub.svg" alt="The Hub by Ragna Labs" class="logo">
      <div>
        <h1>Creamos soluciones en diseño digital y desarrollo web<br/>CMS / e-Commerce / Landing Pages / One Page</h1>
        <h2>&nbsp;&nbsp;&nbsp;Shopify · Webflow · Jumpseller Partners</h2>
      </div>
      <Typeform/>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Typeform from '@/components/Typeform.vue'

export default {
  name: 'Home',
  components: {
    Typeform
  }
}
</script>
